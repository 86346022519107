import { Box, Button, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, SpeedDial, SpeedDialAction, Tooltip, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

import StoreMap from '../../tools/StoreMap';
import { IconComponent } from "@zippeditoolsjs/zippedi-icons";
import { highlightedProductColor, mapBackgroundColor } from "../../utils/colors";
import { colorBasedOnTheme } from "../../utils/colors";
import CloseButton from "../../tools/CloseButton";
import { getChainMeaning } from 'core/utils/parsers';
import { isEmptyOrUndefined } from "core/utils/validators";
import PageLink from "../../tools/PageLink";
import DownloadSection from "./DownloadSection";
import { format } from "date-fns";


export default function NavigationMap(props) {
    const {
        date,
        storeMap,
        taskType,
        setIsSidenavOpen,
        selectedStore,
        selectedStoreTaskType,
        selectedCategory,
        robotSession,
        setRobotSession,
        openTimeline,
        setOpenTimeline,
        heightRef,
        canShowActions = true,
        highlightedBoxes = [],
        isZoomActive,
        poses,
        obstacles,
        visualizationMode: inputVisualizationMode = 'ds_nr_basic',
        taskData,
        clientFormats,
        getDigitalStoreExcel,
        getDigitalStorePdf,
        getDigitalStoreInv,
        getExcelByDate,
        isExcelLoading,
        isPdfLoading,
        isInvLoading,
        isLoadingExcelByDate,
        selectedClient,
        linkReceived,
        storeCategories,
        filteredTaskData,
        supplier,
    } = props;
    const { t } = useTranslation();

    const [categoriesOnMap, setCategoriesOnMap] = useState({});
    const [posesMap, setPosesMap] = useState([]);
    const [obstaclesMap, setObstaclesMap] = useState([]);
    // Speed dial states
    const [visualizationMode, setVisualizationMode] = useState(inputVisualizationMode);
    const [openSpeedDial, setOpenSpeedDial] = useState(false);
    const [openLabel, setOpenLabel] = useState(false);
    const [openRobot, setOpenRobot] = useState(false);
    const speedDialRef = useRef(null);
    const labelRef = useRef(null);
    const robotRef = useRef(null);

    // Hook that closes the speed dial or the filters depending on clicks outside of the passed ref
    useEffect(() => {
        // Close speed dial if clicked on outside of element
        function handleClickOutside(event) {
            if (labelRef.current && !labelRef.current.contains(event.target)) {
                setOpenLabel(false);
            } else if (robotRef.current && !robotRef.current.contains(event.target)) {
                setOpenRobot(false);
            } else if (!labelRef.current && !robotRef.current && speedDialRef.current && !speedDialRef.current.contains(event.target)) {
                setOpenSpeedDial(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [speedDialRef, labelRef, robotRef]);

    // Lyfecycle methods
    useEffect(() => {
        if (posesMap.length === 0 && !openTimeline) {
            setPosesMap(storeMap.task_table_info);
        }
    }, []);

    useEffect(() => {
        if (obstaclesMap.length === 0 && !openTimeline) {
            setObstaclesMap(storeMap.map_info.obstacles);
        }
    }, []);

    // Speed dial actions and handlers
    const actions = [
        { icon: <IconComponent iconName={'filter'} style={{ fontSize: '20px' }} />, name: t('cws_app.digital_store.navigation_filter', 'Navigation filter'), operation: 'label' },
        { icon: <IconComponent iconName={'time-outline'} style={{ fontSize: '20px' }} />, name: t('cws_app.digital_store.timeline', 'Timeline'), operation: 'timeline' },
        { icon: <div onClick={(e) => e.stopPropagation()}>
                    <PageLink
                        currentState={{
                            date,
                            selectedStore,
                            selectedStoreTaskType,
                            selectedCategory
                        }}
                        view="/cpg/digitalStore"
                        text={t('cws_app.general.link', 'Link')}
                    />
                </div>,
                name: t('cws_app.general.copy_link', 'Copy link'), operation: 'link' }
    ];

    const handleSpeedDialOpen = () => setOpenSpeedDial(true);

    const handleSpeedDialActionClick = (e, operation) => {
        e.preventDefault();
        setOpenSpeedDial(true);
        switch (operation) {
            case 'label':
                setOpenLabel(true);
                setOpenRobot(false);
                break;
            case 'timeline':
                setOpenTimeline(true);
                setOpenRobot(false);
                setVisualizationMode('ds_timeline');
            case 'link':
                e.stopPropagation();
            default:
                break;
        }
    }

    const handleNavigationSummaryClick = (e, operation) => {
        setIsSidenavOpen(true);
    }

    // Label Filter
    const handleLabelChange = (event) => {
        setVisualizationMode(event?.target?.value)
        setOpenTimeline(false);
    }

    // Robot Filter
    const handleRobotChange = (event) => {
        setRobotSession(event?.target?.value)
    }

    return (
        <Paper ref={heightRef} style={{ position: 'relative', width: '100%', height: 'fit-content', padding: '0 1em', backgroundColor: mapBackgroundColor() }}>
            {/* Speed dial and custom tooltips */}
            <Grid container direction='row' justifyContent='flex-end' alignContent='flex-end' sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
                
            {canShowActions &&
                <>
                    <SpeedDial
                        ref={speedDialRef}
                        ariaLabel="Map configurations"
                        FabProps={{ size: 'small' }}
                        icon={<IconComponent iconName={'settings'} style={{ fontSize: '20px' }} />}
                        direction="left"
                        open={openSpeedDial}
                        onClick={handleSpeedDialOpen}
                        >
                        {actions.map((action) => {
                            return (
                                <SpeedDialAction
                                key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    tooltipPlacement='top'
                                    onClick={(e) => handleSpeedDialActionClick(e, action.operation)}
                                    />
                                )
                            })}
                            
                    </SpeedDial>
                    <Grid display='flex' justifyContent='flex-end' alignItems='center' sx={{ height: '56px', ml: 2}}>
                        <Tooltip title={t('cws_app.digital_store.navigation_summary', 'Navigation summary')} placement="top">
                            <Button variant='contained'
                                sx={{
                                    backgroundColor: '#1a242d',
                                    borderRadius: '20px',
                                    height: '40px',
                                    minWidth: '40px',
                                    padding: '0'
                                }}
                                onClick={(e) => handleNavigationSummaryClick(e, 'summary')}
                                >
                                <IconComponent iconName={'document'} style={{ fontSize: '20px', color: '#fff' }} />
                            </Button>
                        </Tooltip>
                    </Grid>

                    <Grid display='flex' justifyContent='flex-end' alignItems='center' sx={{ height: '56px', ml: 2}}>
                        <DownloadSection
                            displayedColumns={[]}
                            storeCategories={storeCategories}
                            chain={selectedStore?.chain_name}
                            filteredTaskData={filteredTaskData}
                            isDataFiltered={false}
                            
                            client_id={selectedClient?.client_id}
                            signed_logo={selectedClient?.signed_logo}
                            supplier_id={selectedClient?.supplier_id ? selectedClient?.supplier_id : supplier?.id}
                            
                            inputDate={format(date, 'yyyy-MM-dd')}
                            inputTaskType={selectedStoreTaskType?.task_name}
                            inputStoreId={selectedStore?.store_id}
                            inputStoreName={selectedStore?.parsed_name}
                            
                            getDigitalStoreExcel={getDigitalStoreExcel}
                            getDigitalStorePdf={getDigitalStorePdf}
                            getDigitalStoreInv={getDigitalStoreInv}
                            getExcelByDate={getExcelByDate}
                            
                            isExcelLoading={isExcelLoading}
                            isPdfLoading={isPdfLoading}
                            isInvLoading={isInvLoading}
                            isLoadingExcelByDate={isLoadingExcelByDate}
                            
                            linkReceived={linkReceived}
                            
                            selectedCategory={selectedCategory}
                            
                            clientFormats={clientFormats}
                        />
                    </Grid>
                </>
                }
                {openLabel &&
                    <Paper ref={labelRef} sx={{ position: 'absolute', right: '8em', top: '4em', padding: 1 }}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="label-filter"
                                name="label-filter"
                                value={visualizationMode}
                                onChange={handleLabelChange}
                            >
                                <FormControlLabel value="ds_nr_basic" control={<Radio size='small' />} label={t('cws_app.digital_store.all', 'All')} />
                                <FormControlLabel value="ds_poses" control={<Radio size='small' />} label={t('cws_app.digital_store.route', 'Route')} />
                                <FormControlLabel value="ds_nav_poses" control={<Radio size='small' />} label={t('cws_app.digital_store.nav_mode', 'NAV mode')} />
                                <FormControlLabel value="ds_waf_poses" control={<Radio size='small' />} label={t('cws_app.digital_store.waf_mode', 'WAF mode')} />
                                <FormControlLabel value="ds_obstacles" control={<Radio size='small' />} label={t('cws_app.digital_store.obstacles', 'Obstacles')} />
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                }
                {openRobot &&
                    <Paper ref={robotRef} sx={{ position: 'absolute', right: '5em', top: '4em', padding: 1 }}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="robot-filter"
                                name="robot-filter"
                                value={robotSession}
                                onChange={handleRobotChange}
                            >
                                {storeMap?.sessions.map((session) => {
                                    return (
                                        <FormControlLabel value={session?.session} key={session?.floor_number} control={<Radio size='small' />} label={
                                            t('cws_app.digital_store.sel_floor_i', 'Floor {{floorIndex}}', { floorIndex: session?.floor_number })
                                        }
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                }
            </Grid>
            <StoreMap
                storeMap={storeMap.map_info}
                tasksData={taskData}
                groupingAlertsBy={storeMap.GROUPINGALERTSBY}
                categoriesOnMap={categoriesOnMap}
                visualizationMode={visualizationMode}
                isZoomActive={isZoomActive}
                selectedDots={openTimeline ? poses : posesMap}
                highlightedBoxes={highlightedBoxes}
                tastType={taskType}
                obstacles={openTimeline ? obstacles : obstaclesMap}
            />
        </Paper>
    );
}
