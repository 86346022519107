import React, { useEffect, useState } from 'react'
import { Badge, Card, CardActionArea, CardActions, CardContent, Grid, Pagination, Paper, Tooltip, Typography, useTheme, Divider } from '@mui/material'
import { UpcScan } from 'react-bootstrap-icons';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { lowerCaseAllWordsExceptFirstLetters } from 'core/utils/parsers';
import Canvas from '../../tools/Canvas';
import CloseButton from '../../tools/CloseButton'
import SearchBar from '../../tools/SearchBar';
import { colorBasedOnAlert, colorBasedOnTheme, highlightedProductColor } from '../../utils/colors';
import { convertMeterToPixel, getRobotIndex } from '../../utils/converters';
import { isEmptyOrUndefined } from "core/utils/validators";
import PageLink from '../../tools/PageLink';
import { getConsolidatedTranslations } from 'core/utils/parsers';
import CircularLoader from "../../tools/CircularLoader";

export default function SummaryCard(props) {
  const {
    t,
    storeMap,
    setIsSidenavOpen,
    selectedAlert,
    setSelectedAlert,
    getAisleThumbnail,
    selectedClient,
    isLoadingAisleThumbnail,
    aisleThumbnail,
    selectedStore,
    date,
    aisleLandmarkMap,
    aislesList,
    height,
    sidenavRef,
    robotSession,
    getDSSessionData,
    sessionData,
    isLoadingSessionData,
  } = props;
  const theme = useTheme();
  const [products, setProducts] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [highlightedProduct, setHighlightedProduct] = useState({});
  const [aisleSections, setAisleSections] = useState({}); // Total number of sections (tramos) in the aisle and an array with all, sorted by page
  const [page, setPage] = useState(1);
  const [sectionRange, setSectionRange] = useState({});
  const [currentAlert, setCurrentAlert] = useState({});
  const [hover, setHover] = useState(false);
  const [isSessionData, setIsSessionData] = useState(true);

  // Lifecycle methods

  // Convert date to ISO 8601 format (considering the time zone) and get the thumbnail
  useEffect(() => {
    // The second comparison is to not fetch the thumbnail again if it's the same aisle
    if (selectedStore && currentAlert?.aisle_name !== selectedAlert?.aisle_name) {
      const session = selectedAlert?.alertList[0]?.session;
      getAisleThumbnail(selectedClient?.client_id, selectedStore?.store_id, selectedAlert?.aisle_name, session)
      setCurrentAlert(selectedAlert)
    }
  }, [selectedAlert])


  // Reset product list and clicked product
  useEffect(() => {
    setProducts(selectedAlert?.alertList)
    setHighlightedProduct({})
    // Set the range of meters in which the section (tramo) belongs to
    setSectionRange({ from: 3 * (selectedAlert?.tramo - 1), to: 3 * selectedAlert?.tramo }) // Formula: [3 * (n - 1), 3 * n]
  }, [selectedAlert])

  // Filter the product's list based on the searchBar
  useEffect(() => {
    if (typeof value === 'string' || typeof inputValue === 'string') {
      const filteredProducts = selectedAlert?.alertList.filter(product => product?.description?.toLowerCase().includes(inputValue?.toLowerCase()))
      setProducts(filteredProducts)
    }
  }, [value, inputValue])

  // Total sections in the aisle and dict with the sections for the pagination and metadata at the top of the thumbnail
  useEffect(() => {
    let sections = { totalSections: 0, sections: {} };
    if (aisleLandmarkMap && selectedAlert) {
      sections['totalSections'] = aisleLandmarkMap[selectedAlert['aisle_name']].length
      for (let index = 0; index < sections['totalSections']; index++) {
        const section = aislesList[aisleLandmarkMap[selectedAlert['aisle_name']][index]]
        sections['sections'][index + 1] = section // + 1 so it matches the pagination number
        // Jump to the corresponding page, that matches the selected section (tramo)
        if (section?.tramo === selectedAlert['tramo']) {
          setPage(index + 1)
        }
      }
      setAisleSections(sections)
    }
  }, [selectedAlert])

  // Methods

  // Draw every a dot on the thumbnail for every product with an alert
  const drawDots = (ctx) => {
    let minPositionX = 1000000; // Number that's bigger than any thumbnail size for the automatic scroll
    selectedAlert?.alertList.forEach(alert => {
      const [x, y] = convertMeterToPixel(alert?.location_x, alert?.location_z, aisleThumbnail?.scale)
      drawCircle(ctx, x, y, 3, colorBasedOnAlert(alert?.task_type))
      minPositionX = x < minPositionX ? x : minPositionX;
    })

    // Scroll to the position of the product closest to the origin in the x-axis
    const thumbnailScroll = document.getElementById("thumbnailScroll");
    if (minPositionX && thumbnailScroll) {
      thumbnailScroll.scrollTo(minPositionX, 0);
    }

    // Change the color of the product if it's clicked
    if (!isEmptyOrUndefined(highlightedProduct, 'dict')) {
      const [x, y] = convertMeterToPixel(highlightedProduct?.location_x, highlightedProduct?.location_z, aisleThumbnail?.scale)
      drawCircle(ctx, x, y, 3, highlightedProductColor(highlightedProduct?.task_type))
    }
  }

  function drawCircle(ctx, x, y, radius, fill, stroke, strokeWidth) {
    ctx.beginPath()
    ctx.arc(x, y, radius, 0, 2 * Math.PI, false)
    if (fill) {
      ctx.fillStyle = fill
      ctx.fill()
    }
    if (stroke) {
      ctx.lineWidth = strokeWidth
      ctx.strokeStyle = stroke
      ctx.stroke()
    }
  }

  // Scroll to the position of the clicked product
  const handleClick = (alert) => {
    setHighlightedProduct(alert)
    const thumbnailScroll = document.getElementById("thumbnailScroll");
    const coordinates = convertMeterToPixel(alert?.location_x, null, aisleThumbnail?.scale)
    if (thumbnailScroll && coordinates) {
      thumbnailScroll.scrollTo(coordinates[0], 0);
      scrollToTop();
    }
  }

  // Scroll the whole sidenav component to the top
  const scrollToTop = () => {
    sidenavRef.current.scroll({
      top: 0,
      behavior: "smooth"
    });
  };

  const handlePaginagionChange = (_, value) => {
    setPage(value);
    setSelectedAlert(aisleSections?.sections[value])
  }

  return (
    <Paper ref={sidenavRef} sx={{ position: 'relative', width: { xs: '100vh', md: '35%' }, height: 'auto', ml: { xs: 0, md: 1 }, mt: { xs: 1, md: 0 }, px: 2, overflow: 'auto' }}>
      {/* Header */}
      <Grid container item justifyContent='flex-end' alignItems='center' sx={{ position: 'absolute', right: '1%' }}>
        <CloseButton setIsOpen={setIsSidenavOpen} isColorbgAlwaysDark={false} />
      </Grid>
      {(isLoadingSessionData)?
        <Grid display='flex' alignItems='center' justifyContent='center' flexDirection='column' sx={{ height: '100%' }}>
          <Grid container item direction='column' justifyContent='center' alignItems='center' my={12}>
            <CircularLoader />
            <Typography>{t('cws_app.digital_store.loading_navigation_summary', 'Loading navigation summary')}</Typography>
          </Grid>
        </Grid>
        :
        <>
        {/* Session title */}
        <Grid container item justifyContent='start' mt={4}>
          <Typography variant='h6'>{t('cws_app.digital_store.session', 'Session')} {robotSession.split('-')[0].trim()}</Typography>
        </Grid>
        {/* Obstacles and general information */}
        <Grid container item direction='column' justifyContent='center' alignItems='center' my={1}>
          {/* Planned navigation start and arrival time */}
          <Grid container alignItems='flex-start'>
            <Grid container item xs={6}>
              <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.planned_start_time', 'Planned start time')}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{sessionData.session_data[0].planned_for}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.planned_arrival_time', 'Planned arrival time')}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{sessionData.session_data[0].planned_arrival}</Typography>
            </Grid>
          </Grid>
          <Divider style={{ width: '-webkit-fill-available', marginTop: 8, marginBottom: 8}}/>
          {/* Day and permanent obstacles */}
          <Grid container item justifyContent='space-around' flex-direction='row'>
            <Grid container item xs={6} alignItems='flex-start' flexDirection='column'>
              <Typography variant='h6' style={{fontSize: '14px'}}>{t('cws_app.digital_store.day_obstacles', 'Day obstacles')}</Typography>
              <Divider style={{ backgroundColor: '#fd6303', width: '87%', height: '2px', marginTop: 6, marginBottom: 4}}/>
              <Typography variant='h4' style={{color: '#fd6303'}}>{storeMap.day_obstacles}</Typography>
            </Grid>
            <Grid container item xs={6} alignItems='flex-start' flexDirection='column'>
              <Typography variant='h6' style={{fontSize: '14px'}}>{t('cws_app.digital_store.constant_obstacles', 'Constant obstacles')}</Typography>
              <Divider style={{ backgroundColor: '#9d03fd', width: '87%', height: '2px', marginTop: 6, marginBottom: 4}}/>
              <Typography variant='h4' style={{color: '#9d03fd'}}>{storeMap.permanent_obstacles}</Typography>
            </Grid>
          </Grid>
          {/* Robot states */}
          <Grid container item alignItems='flex-start' flexDirection='column' mt={2}>
            <Grid container item alignItems='center' flexDirection='row'>
              <Divider style={{ borderStyle: 'dotted', borderColor: '#00ff00', borderWidth: '4px', width: '20%', borderBottom: '0px', marginTop: 6, marginBottom: 4, marginRight: 10}}/>
              <Typography variant='h6' style={{fontSize: '14px'}}>{t('cws_app.digital_store.navigation_mode', 'Navigation mode')}</Typography>
            </Grid>
            <Grid container item alignItems='center' flexDirection='row'>
              <Divider style={{ borderStyle: 'dotted', borderColor: '#0000ff', borderWidth: '4px', width: '20%', borderBottom: '0px', marginTop: 6, marginBottom: 4, marginRight: 10}}/>
              <Typography variant='h6' style={{fontSize: '14px'}}>{t('cws_app.digital_store.data_capture_mode', 'Data capture mode')}</Typography>
            </Grid>
            <Grid container item alignItems='center' flexDirection='row'>
              <Divider style={{ borderStyle: 'dotted', borderColor: '#ff0000', borderWidth: '4px', width: '20%', borderBottom: '0px', marginTop: 6, marginBottom: 4, marginRight: 10}}/>
              <Typography variant='h6' style={{fontSize: '14px'}}>{t('cws_app.digital_store.robot_disoriented', 'Robot disoriented')}</Typography>
            </Grid>
          </Grid>
          <Divider style={{ width: '-webkit-fill-available', marginTop: 8, marginBottom: 8}}/>
          {/* General information */}
          <Grid container alignItems='flex-start'>
            <Typography variant='h6' style={{fontSize: '14px'}}>{t('cws_app.digital_store.general_information', 'General information')}</Typography>
          </Grid>
          <Grid container alignItems='flex-start'>
            <Grid container item xs={6}>
              <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.actual_start_time', 'Actual start time')}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{sessionData.session_data[0].nav_start}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.actual_arrival_time', 'Actual arrival time')}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{sessionData.session_data[0].nav_finish}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.total_navigation_time', 'Total navigation time')}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{sessionData.session_data[0].nav_time}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.planned_linears', 'Planned linears')}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{sessionData.aisles_data[0].total_active_linears}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.navigated_linears', 'Navigated linears')}</Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{sessionData.aisles_data[0].total_navigated_linears}</Typography>
            </Grid>
            <Grid container item xs={6} alignItems='center'>
              <Tooltip title={t('cws_app.digital_store.planned_vs_navigated', 'Planned linears vs navigated linears')} arrow>
                <Typography color="textSecondary" style={{fontSize: '14px'}}>{t('cws_app.digital_store.compliance', 'Compliance')}</Typography>
              </Tooltip>
            </Grid>
            <Grid container item xs={6}>
              <Typography style={{fontSize: '14px'}}>{Math.round((sessionData.aisles_data[0].total_navigated_linears/sessionData.aisles_data[0].total_active_linears) * 1000) / 10}%</Typography>
            </Grid>
          </Grid>
        </Grid>
        </>
      }
    </Paper>
  )
}