import React, { useEffect, useState } from 'react'
import { Grid, Paper, Slider, Stack } from '@mui/material'
import { styled } from "@mui/system";

const VerticalThumbSlider = styled(Slider)({
  height: 0,
  "& .MuiSlider-thumb": {
    width: "4px",  // Ancho pequeño
    height: "25px", // Alto grande para parecer una línea
    borderRadius: '2px',
    backgroundColor: '#1a242d',
    "&:hover, &.Mui-active, &.Mui-focusVisible": { // Quitar color en hover o selección
      boxShadow: 'none',
    },
  },

  "& .MuiSlider-track": {
    display: 'none',
  },

  "&::after": {
      content: '""',
      position: "absolute",
      top: "-30px", // Ajustar posición sobre el thumb
      left: "50%",
      transform: "translateX(-50%)",
      width: "20px", // Tamaño del icono
      height: "20px", // Tamaño del icono
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
  },
});

export default function NavigationTimeline(props) {
  const {
    t,
    storeMap,
    poses,
    obstacles,
    setPoses,
    setObstacles,
    sidenavRef
  } = props;

  const [maxValue, setMaxValue] = useState(30);
  const [value, setValue] = useState(0);

  // useEffect(() => {
  //   if (storeMap?.task_table_info){
  //     setObstacles([])
  //     setPoses([])
  //     setMaxValue(storeMap.task_table_info.length - 1)
  //   }
  // }, [])

  useEffect(() => {
    if (storeMap?.task_table_info) {
      setObstacles([]);
      setPoses([]);
      setMaxValue(storeMap.task_table_info.length - 1);
    }
  }, [storeMap, setObstacles, setPoses, setMaxValue]);
  
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPoses(storeMap.task_table_info.slice(0, newValue));

    if (!storeMap?.map_info?.obstacles) return;

    let lastObstacleIndex = -1;

    const filtered = storeMap.map_info.obstacles.filter((obstacle, obsIndex) => {
      return storeMap.task_table_info.slice(0, newValue).some((pose, poseIndex) => {
        const isMatch =
          Math.abs(obstacle.px_initial[0] - pose.x) <= 50 &&
          Math.abs(obstacle.px_initial[1] - pose.y) <= 50 &&
          obstacle.mode === pose.mode;

        if (isMatch) {
          lastObstacleIndex = Math.max(lastObstacleIndex, obsIndex);
        }

        return isMatch;
      });
    });

    setObstacles(filtered.slice(0, lastObstacleIndex + 1));
  };

  return (
    <Paper ref={sidenavRef} sx={{ height: '100%' }}>
      <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", display: "flex" }}>
      {(() => {
        let count = 0;
        const processedObstacles = new Set();

        return storeMap?.task_table_info.map((pose, index) => {
          const matchingObstacle = storeMap?.map_info?.obstacles?.find((obstacle) => {
            const isMatch =
              Math.abs(obstacle.px_initial[0] - pose.x) <= 30 &&
              Math.abs(obstacle.px_initial[1] - pose.y) <= 30 &&
              obstacle.mode === pose.mode;

            if (isMatch && !processedObstacles.has(obstacle)) {
              processedObstacles.add(obstacle);
              count++;
              return true;
            }

            return false;
          });

          if (!matchingObstacle) return null;

          const totalPoses = maxValue;
          const normalizedX = (index / totalPoses) * 100;

          return (
            <div
              key={index}
              style={{
                position: "absolute",
                left: `${normalizedX}%`,
                width: "1px",
                height: "100%",
                backgroundColor: matchingObstacle.permanent ? "#9d03fd" : "#fd6303",
              }}
            />
          );
        });
      })()}
      </div>

      <Grid sx={{ width: '100%' }}>
        <Stack spacing={2} direction="row" sx={{ alignItems: 'center', mb: 1 }}>
          <VerticalThumbSlider
            min={0}
            max={maxValue}
            aria-label="Volume"
            value={value}
            onChange={handleChange}
            valueLabelDisplay="on"
          />
        </Stack>
      </Grid>
    </Paper>
  )
};